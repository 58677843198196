import React from "react";

import { CssBaseline, Grid } from "@material-ui/core";

import MainLayout from "../components/layouts/main";
import SectionLayout from "../components/layouts/section";
import Title from "../components/typography/title";
import Subtitle from "../components/typography/subtitle";
import colors from "../components/styling/colors";

import Image404 from "../images/image404.svg";

const Main: React.FC = () => {
	return (
		<MainLayout>
			<CssBaseline />
			<SectionLayout background={colors.MEDIUM_GRAY}>
				<Grid container direction="column" justify="center" alignItems="center" spacing={5}>
					<Grid item container justify="center">
						<Image404 style={{ maxWidth: "80%", height: "100%" }} />
					</Grid>

					<Grid item>
						<Title variant="h1">Page Not Found</Title>
					</Grid>

					<Grid item>
						<Subtitle variant="h2">Whoops! Looks like the page you're looking for is not available.</Subtitle>
					</Grid>
				</Grid>
			</SectionLayout>
		</MainLayout>
	);
};

export default Main;
